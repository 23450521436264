.header {
  cursor: pointer;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header.noToggle {
  cursor: default;
}

.headerTitle {
  text-transform: uppercase;
  flex: 1;
}

.subTitle {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  flex: 1;
}

.toggleMinus {
  background: url("../../../../assets/images/printemps_down-arrow.png")
    center/contain no-repeat;
}

.togglePlus {
  background: url("../../../../assets/images/printemps_right-arrow.png")
    center/contain no-repeat;
}

.toggleMinus,
.togglePlus {
  width: 12px;
  height: 12px;
  color: black;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .header {
    align-items: flex-start;
  }
  .header.short {
    align-items: center;
  }
}
