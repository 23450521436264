@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

.carouselContainer {
  width: 100%;
  font-weight: bold;
}

.cardContainer {
  padding: 0;
  max-width: 100%;
  width: 100%;
}

/*
** Pagination CSS
*/

.printDots {
  bottom: 25px;
}

.printDots li .printDot {
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  color: white;
}

.printDots li .printDot::before {
  opacity: 0;
  font-size: 16px;
  line-height: 20px;
  left: -2px;
  top: -1px;
  color: white !important;
}

.printDots li .printDot.dark::before {
  color: black !important;
}

.printDots li .printDot.dark {
  border: 2px solid rgba(0, 0, 0, 0.5);
  color: black;
}

.printDots li.slick-active .printDot::before {
  opacity: 1;
}

.printDots li {
  width: 16px;
  height: 16px;
  margin: 0 2px;
  padding: 0;
  cursor: pointer;
}

@media (max-width: 767px) {
  .productCarousel :global(.slick-slide) {
    max-width: 50% !important;
  }
}
