.customArrow {
  height: 40px !important;
  width: 40px !important;
  top: 50% !important;
  z-index: 5;
  opacity: 0.5;
}

.customArrow::before {
  display: none;
}

.leftArrow {
  left: 15px !important;
  background-color: white !important;
  mask: url("../../../../../../assets/images/left-arrow.svg") no-repeat 50% 50%/40px
    40px !important;
  mask-size: contain;
}

.rightArrow {
  right: 15px !important;
  background-color: white !important;
  mask: url("../../../../../../assets/images/right-arrow.svg") no-repeat 50% 50%/40px
    40px !important;
  mask-size: contain;
}

.downArrow,
.upArrow {
  height: 20px !important;
  width: 100% !important;
}

.upArrow {
  top: -20px !important;
  left: 0 !important;
  background-color: black !important;
  mask: url("../../../../../../assets/images/up-arrow.svg") no-repeat 50% 50%/20px
    20px !important;
  mask-size: contain;
}

.downArrow {
  top: calc(100% + 15px) !important;
  right: 0 !important;
  background-color: black !important;
  mask: url("../../../../../../assets/images/down-arrow.svg") no-repeat 50% 50%/20px
    20px !important;
  mask-size: contain;
}

.smallLeftArrow {
  left: -20px !important;
  top: 50% !important;
  background-color: black !important;
  mask: url("../../../../../../assets/images/left-arrow.svg") no-repeat 50% 50%/20px
    20px !important;
  mask-size: contain;
}

.smallRightArrow {
  top: 50% !important;
  right: -20px !important;
  background-color: black !important;
  mask: url("../../../../../../assets/images/right-arrow.svg") no-repeat 50% 50%/20px
    20px !important;
  mask-size: contain;
}

.dark {
  background-color: black !important;
}

.uptArrow:hover,
.downtArrow:hover,
.leftArrow:hover,
.rightArrow:hover,
.smallLeftArrow:hover,
.smallRightArrow:hover {
  opacity: 0.75;
}

.upArrow:focus,
.downArrow:focus,
.leftArrow:focus,
.rightArrow:focus,
.smallLeftArrow:focus,
.smallRightArrow:focus {
  opacity: 0.5 !important;
}

.customArrow:focus:global(.slick-dsabled) {
  opacity: 0.25 !important;
}

@media (max-width: 1024px) {
  .upArrow {
    top: -20px !important;
  }
  .downArrow {
    top: calc(100% + 15px) !important;
  }

  .leftArrow {
    left: 15px !important;
    mask: url("../../../../../../assets/images/left-arrow.svg") no-repeat 50%
      50%/30px 30px !important;
  }

  .rightArrow {
    right: 15px !important;
    mask: url("../../../../../../assets/images/right-arrow.svg") no-repeat 50%
      50%/30px 30px !important;
  }
}
