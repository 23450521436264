.filtersBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}
.filtersBar.centered {
  justify-content: center;
}

.filterSelect {
  margin-right: 2rem;
}

@media (max-width: 767px) {
  .filtersBar {
    flex-direction: column;
  }

  .filtersBar > div {
    width: 100%;
  }
  .filterSelect {
    margin: 0.25em 0;
    /* width: 100%; */
  }
}
