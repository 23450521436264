.filtersBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.filterSelect {
  margin-right: 2rem;
  min-width: 196px !important;
}

@media (max-width: 1024px) {
  .filterSelect {
    margin-right: 2rem;
    min-width: 150px !important;
  }
}
@media (max-width: 767px) {
  .filtersBar {
    flex-direction: column;
  }

  .filterSelect {
    margin: 0.25em 0;
    width: 100%;
  }
}
