.bulletsContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.separator {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 0.5px solid black;
}

.circle {
  position: relative;
  z-index: 1;
  height: 50px;
  width: 50px;
  background-color: white;
  border: 2px solid;
  border-radius: 50%;
  border-color: black;
}

.bullet {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
  font-size: 2.4rem;
}

.inverted {
  color: white;
  background-color: black;
}

.disabled {
  color: grey;
  border-color: grey;
}
