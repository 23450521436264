/* Inputs */
textarea {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  border-color: #d3d3d3 !important;
}
.ui.form textarea {
  border-radius: 0 !important;
  border-color: #d3d3d3 !important;
}
.ui.input {
  border-radius: 0 !important;
  border-color: #d3d3d3 !important;
}
.ui.disabled.input {
  background: #d3d3d3;
}
.ui.input > input,
.ui.form input[type="tel"],
.ui.form input[type="email"] {
  border: none;
}
.ui.input > input {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  border-radius: 0;
}
.ui.disabled.input > input {
  background: #d3d3d3;
}

/* Surcharge React-Slick */

.horizontal .slick-slide {
  height: inherit !important;
  max-width: calc(100% / 4);
}

.horizontal .center .slick-slide {
  opacity: 0.75 !important;
  transition: opacity 0.2s;
}

.horizontal .center .slick-center {
  opacity: 1 !important;
  transition: opacity 0.2s;
}

.horizontal .slick-track {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}

.horizontal .slick-slide > div {
  display: flex;
  height: 100% !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.25;
}
