.numericTextbox {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.numericTextbox .input {
  width: 8rem;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 1 !important;
}

.numericTextbox .input input {
  text-align: center;
  color: #000;
}

.numericTextbox .btn {
  margin-top: auto !important;
  margin-bottom: auto !important;
  padding: 0 0.5rem;
  font-size: 20px;
  border: none;
  cursor: pointer;
}

.numericTextbox .btn:focus,
.numericTextbox .btn:active,
.numericTextbox .btn {
  background: transparent;
}

/* .numericTextbox .btn:hover {
  color: #fff !important;
  background: #000 !important;
} */
.numericTextbox .inputBox {
  display: flex;
}

.numericTextbox .label {
  display: flex;
  flex: 1;
  padding-right: 1rem;
}

.numericTextbox .unit {
  margin-top: auto !important;
  margin-bottom: auto !important;
  padding-left: 0.5rem;
}

.numericTextbox .inputWithoutButtons {
  width: 15.5rem;
  border: 1px solid #d3d3d3;
}

.numericTextbox .unit {
  color: #000;
  position: absolute;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.numericTextbox .inputContainer {
  position: relative;
}

@media (max-width: 1024px) {
  .numericTextbox {
    flex-direction: column;
    align-items: flex-end;
  }

  .numericTextbox .label {
    display: flex;
    flex: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .numericTextbox {
    margin: 2rem 0;
    align-items: flex-start;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}