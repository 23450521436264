.container {
    display: flex;
    flex-direction: row;
}

.thumbnails {
    margin: 1rem 0;
    width: 100px;
    padding: 2rem 1rem;
    max-height: 300px;
}

.box {
    position: relative;
    min-width: 30px;
}

.thumbnails .box,
.thumbnails .videoBox {
    border: 1px solid rgb(200, 200, 200);
}

.thumbnails .box:hover,
.thumbnails .box.active,
.thumbnails .videoBox:hover,
.thumbnails .videoBox.active {
    border: 1px solid black;
}

.activeImgContainer {
    width: 100%;
    padding: 0;
    margin: auto;
    background-color: white;
    border-width: 0;
    max-width: 400px;
}

.box::after {
    content: "";
    display: block;
    padding-top: 100%;
}

.box > picture {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
}

.img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.modalContent {
    display: flex;
    flex-direction: row;
}

.modalCurrentImgContainer {
    width: 100%;
    max-width: 800px;
}

.productLabels {
    font-size: 1.6rem;
    margin-bottom: 1rem;
}
.productName {
    font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
}

.productBrand {
    font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
}

.modalPictureContainer {
    position: relative;
    display: inline-block;
}

.modalZoomContainer {
    position: absolute;
    bottom: 0;
    right: 0;
}

.modalZoomBtn {
    height: 2.8rem;
    width: 2.8rem;
    border-width: 0;
    background: url("../../../../../assets/images/search.png") center/contain
        no-repeat;
    cursor: pointer;
    margin-bottom: 3px;
}

.modalCloseBtn {
    padding: 0 !important;
    margin-right: 3px;
    height: 2rem;
    width: 2rem;
    background-color: #000 !important;
    mask: url("../../../../../assets/images/close.svg") no-repeat 50% 50%/2rem
        2rem !important;
    mask-size: contain;
    cursor: pointer;
}

.videoBox {
    padding: 0 10px;
}

.videoThumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
}

.videoThumbnail video {
    height: 100%;
    pointer-events: none;
}

.videoCircle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
}

.videoArrow {
    height: 16px;
    width: 16px;
    background: url("../../../../../assets/images/right-arrow-rounded.png")
        center/contain no-repeat;
}

video {
    width: 100%;
}

@media (max-width: 1366px) {
    .box {
        max-height: 85vh;
    }

    .box > picture {
        max-width: 400px;
        max-height: 400px;
    }
}

@media (max-width: 1024px) {
    .thumbnails {
        margin: 1rem 0;
        width: 80px;
        max-height: 300px;
    }
}
