.rangeValues {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* rc-slider (slider/range picker) overrides */
:global(.rc-slider-handle) {
  border: solid 2px #f7ceb8 !important;
}
:global(.rc-slider-track) {
  background-color: #f7ceb8 !important;
}
:global(.rc-slider-handle:active) {
  border-color: #f7ceb8 !important;
  box-shadow: 0 0 5px #f7ceb8 !important;
  cursor: grabbing;
}
