.container {
  margin-bottom: 20px;
}

.tabHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.tabHeaderItem {
  /* border-bottom: 1px solid grey; */
}

.tabHeaderItemBtn {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  font-size: 1.6rem;
  margin: 0 6px;
}

.tabHeaderItemBtn:first-of-type {
  padding-left: 0;
}
.tabHeaderItemBtn:last-of-type {
  padding-right: 0;
}
.tabHeaderItemBtn.activeTab {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  border-bottom: 1px solid black;
}

.tabContent {
}

@media (max-width: 767px) {
  .tabHeaderItemBtn {
    font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 1.6rem;
    margin: 0 6px;
  }
}
