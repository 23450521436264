.numericLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.numericLabel .label {
  display: flex;
  flex: 1;
}
.numericLabel .value {
  display: flex;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
}

.numericLabel.column {
  flex-direction: column;
  align-items: flex-start;
}
.numericLabel.column .label {
  color: #a9a9a9;
  font-size: 1rem;
}

.numericLabel.column.alignRight {
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .numericLabel.column .label {
    font-size: 1.4rem;
  }
}

@media print {
  .numericLabel .value {
    font-size: 1rem;
  }

  .numericLabel.column .label {
    font-size: 0.8rem;
  }
}
