.accordion {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif !important;
}

.title {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif !important;
  font-size: 1.6rem !important;
}

:global(.ui.accordion .title .dropdown.icon).rotate {
  transform: rotate(90deg);
}
