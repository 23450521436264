.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.thumbnails {
    margin: 1rem 0;
    width: 100%;
    padding: 1rem;
    max-height: 100px;
}

.box {
    position: relative;
    min-width: 30px;
}

.thumbnails .box,
.thumbnails .videoBox {
    max-width: 50px;
    border: 1px solid rgb(200, 200, 200);
}

.thumbnails .box:hover,
.thumbnails .box.active,
.thumbnails .videoBox.active {
    border: 1px solid black;
}

.box::after {
    content: "";
    display: block;
    padding-top: 100%;
}

.box > picture {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
}

.img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.activeImgContainer {
    margin: auto;
    background-color: white;
    border-width: 0;
    width: 100%;
    height: 100%;
    max-width: 400px;
}

.videoBox {
    padding: 0 0.8rem;
    height: 100%;
}

.videoThumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.videoThumbnail video {
    height: 6rem;
    pointer-events: none;
}

.videoCircle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
}

.videoArrow {
    height: 12px;
    width: 12px;
    background: url("../../../../../assets/images/right-arrow-rounded.png")
        center/contain no-repeat;
}

video {
    width: 100%;
}

.slick-slider div {
    transition: none;
}
